<template>
  <main style="background-color:white;padding-bottom: 40px;">
    <v-row no-gutters>
      <v-col
        cols="12"
        xs="12"
        style="padding-left: 70px;padding-top: 40px;text-aling: center"
        v-if="size && url"
      >
      <center>
        <v-card 
          elevation="3"
          style="width:1190px; height:670px;"
        >
            <iframe 
              :key="url"
              :src="url"
              id="frame"
              frameborder="0"
              marginwidth="0"
              marginheight="0"
              hspace="0"
              vspace="0"
              scrolling="no"
            />
        </v-card>
      </center>
      </v-col>
    </v-row>
  </main>
</template>

<script>
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    url: null,
    fullscreen: true,
    isInverted: false,
    size: null,
    screensaverTime: 300000,
  }),
  computed: {
    times () {
      return [
        {
          name: '5min',
          time: 300000,
        },
        {
          name: '5seg',
          time: 5000,
        },
      ]
    },
  },  
  watch: {
    screensaverTime () {
      this.handlePrepareURL()
    },
    dialog () {
      this.handlePrepareURL()
    },
  },
  mounted () {
    this.handlePrepareURL()
  },
  methods: {
    getParameterByName (name) {
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    },
    async handleGetSize () {
      return this.scaleSize({
        width: 1080,
        height: 1920,
      })
    },
    async handlePrepareURL () {
      this.url = `https://widgets.dviewcontent.com/cm-totem-activity-barcelo/?id=${this.workspaceID}&meteoID=Santa_Cruz&lmu=${new Date().toISOString()}`
      this.size = await this.handleGetSize()
    },
    scaleSize (v) {
      const maxHeight = 800
      if (v.height > maxHeight) {
        v.width = v.width * maxHeight / v.height
        v.height = maxHeight
      }
      return v
    },
  },
}
</script>

<style>
* {
  font-family: 'Roboto';
}
#frame {
  width: 1920px;
  height: 1080px;
  background-color: transparent;
  -ms-zoom: 0.42;
  -moz-transform: scale(0.62);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.62);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.62);
  -webkit-transform-origin: 0 0;
  border-radius: 10px;
}
</style>



