<template>
  <div style="padding: 0 40px">
    <br/>
      <div>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="D�a a mostrar"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              style="width:240px;"
              hide-details
            />
            <small style="color: #7c7c7c">Seleccione el d�a del calendario a mostrar</small>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
            :first-day-of-week="1"
            :min="minDate"
            :locale="locale"
          />
        </v-menu>
        <!--v-select
          :items="[0, 1, 2, 3, 4, 5, 6, 7]"
          label="D�a a mostrar"
          outlined
          v-model="days"
          dense
          hide-details
        /-->
      </div>
    <center>
    <div id="meeting-room-preview" style="max-width: 800px;">
      <iframe 
        v-if="url"
        class="mr-preview" 
        :src="url" 
        style="width: 100%;margin-top:40px;border: 10px inset gray" 
        :height="height" 
      />
    </div>
    </center>
  </div>
</template>
<script>
//import { mapState } from 'vuex'
//import api from '@/services/api'
//import utils from '@/services/utils'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
  },
  data: () => ({
    menu: false,
    days: 0,
    url: null,
    height: 1080,
    date: new Date().toISOString().split('T').shift(),
    minDate: new Date().toISOString().split('T').shift()
  }),
  watch: {
    workspaceID () {
      this.getUrl()
    },
    days () {
      this.getUrl()
    },
    date () {
      var fechaInicio = new Date().getTime()
      var fechaFin    = new Date(this.date).getTime()

      const diff = fechaFin - fechaInicio

      this.days = Math.ceil(diff/(1000*60*60*24))
    },
  },
  mounted () {
    window.addEventListener('resize', this.setWidth)
    this.getUrl()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth)
    console.log('beforeDestroy')
  },
  methods: {
    setWidth () {
      this.getUrl()
    },
    getUrl () {
      this.$nextTick(() => {
        const e = document.getElementById('meeting-room-preview')
        let width = e ? e.offsetWidth : null
        if (!width) return
        const zoom = ((width-30)/3520)*100
        this.height = (1080 * width)/1920
        //.offsetWidth
        //console.log(width)
        this.url = `https://widgets.dviewcontent.com/meetingRoom/?zoom=${zoom}&addDays=${this.days}&id=${this.workspaceID}` + `${this.workspaceID==='5f7506c9-cf22-4702-8358-79b5653593be' ? '&header=HC1' : ''}`
      })
    },
  }
}
</script>
<style scoped>
.mr-preview {
  border: 0;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
.mr-preview::-webkit-scrollbar {
  display: none; /* For WebKit browsers */
}
</style>

